import { Controller } from "stimulus";

export default class extends Controller {
  deleteFavorite(e) {
    let sku = e.target.closest('div[product_sku]').getAttribute('product_sku')
    let favorite_list_sku = $('.favorite_list').attr('favorite_list_id');
    let data = { favorite_list_sku: favorite_list_sku, product_sku: sku };

    toastr.info('Deleting Favorite');
    
    $.ajax((`/api/favorites/${sku}`), {
      method: 'DELETE',
      async: true,
      cache: false,
      timeout: 5000,
      data: data,
      headers: { 'X-CSRF-Token': $('meta[name=csrf-token]').prop('content') }
    }).done(function () {
      window.location.reload();
    }).fail(function () {
      toastr.error('Failed To Save Favorites');
    });
  }

  checkoutFavoritesList(e) {
    let favorite_list_id = e.target.closest('[favorites_list_id]').getAttribute('favorites_list_id');

    $.ajax((`/api/check-out-favorites-list/${favorite_list_id}`), {
      method: 'POST',
      async: false,
      cache: false,
      timeout: 5000,
      headers: { 'X-CSRF-Token': $('meta[name=csrf-token]').prop('content') }
    })
      .done(function (objData) {
        if (objData.boolSuccess) window.location.href = objData.strCheckoutURL;
        else toastr.error('Failed To Build Cart For Favorites List');
      })
      .fail(function () {
        toastr.error('Failed To Build Cart For Favorites List');
      });
  }
}
